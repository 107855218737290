@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
input[type="range"] {
    -webkit-appearance: none;
    transition: 300ms;
    background: rgba(255, 255, 255, 0.1);
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    border: none;
    border-radius: 3px;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 5px;
    width: 15%;
    /* position: absolute; */
    /* border-radius: 0%; */
    border-radius: 100px;
    top: 50%;
    transform: translateY(80%);
    -webkit-transition: 300ms;
    transition: 300ms;
    margin-top: -4px;
    z-index: 100;
  }
  
  input[type="range"]:focus {
    outline: none;
    transition: 300ms;
  }
  
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .carousel-container-with-scrollbar {
    /* padding-left: 100px; */
    padding-bottom: 20px;
    overflow: visible !important;
    transition: 300ms;
  }
  .custom-slider {
    position: absolute;
    width: 100%;
    bottom: -50px;
    /* padding: 0 20px; */
  }
  .sliderInput {
    width: 100%;
    transition: 300ms;
  }


  

  
  
  

  .ChartWrapper{
    position: absolute;
    bottom: 0;
    width: 100%;
    /* right: -2px; */
    opacity: 0.5;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  .ChartWrapper canvas{
    max-width: 396px;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .CardContent{
    padding: 28px 36px;
    display: flex;
    /* justify-content: center; */
    height: 100%;
    flex-direction: column;
  }
  .CardContent h2{
    font-family: 'Inter', sans-serif;
    margin: 0;
  }
  .CardContent h2:nth-child(1){
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  .CardContent h2:nth-child(2){
    font-weight: bold;
    font-size: 26px;
    margin: 15px 0;
    color: #FFFFFF;
  }
  .CardContent h2:nth-child(3){
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .ButtonArrow{
    position: absolute;
    /* bottom: -40px; */
    top: 100%;
    margin: 34px 0;
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    cursor: pointer;
  }
  .ButtonArrow:before{
    display: none;
  }
  .slick-disabled{
    opacity: 0.5 !important;
    cursor: default !important;
  }

  
  .ButtonArrowLeft{
    left: 0;
  }
  .ButtonArrowRight{
    right: 0;
  }
    .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform,transition;
    }
    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
    }
  ul{
    list-style: none;
  }


  .table-card-toggle{
    /* background: #FBFCFD;  */
    border-bottom: 2px solid #F9F9FB;
  }


  .footer-container{
    position: relative;
    border-top: 2px solid rgba(233, 233, 252, 0.1); 
    display: flex;
    background: #F6F6FC;
    /* padding-bottom: 15px; */
  }


.footerWrapper{
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* transform: translateX(-50%);
  left: 50%; */
}

.footerLinks{
  max-width: 280px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin: 50px 0 20px 0; */
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
}
.footerLinks a{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #AAA8E9;
  text-decoration: none;
}
.footerLinks a:hover{
  /* color: #AAA8E9; */
}
@media (max-width: 992px){
  .footerWrapper{
    flex-direction: column;
    padding: 12px 0;
  }
  .footerLinks a{
    font-size: 7px;    
    margin: 0 2px;
  }
}
#performanceChart{
  max-height: 300px;
}









:root {
  --dark-bg-color: #100F36;
  --light-bg-color: #F9F9FE;
}

.mainPage {
  background-color: #100F36;
  background-color: var(--dark-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.light {
  background-color: #F9F9FE;
  background-color: var(--light-bg-color);  
}


















:root {
    --dark-background: rgb(233, 233, 252, .05);
    --light-background: white;
    --dark-text-color: white;
    --light-text-color: #393F56;
    --dark-rect-color: rgba(176, 167, 255, 0.2);
    --dark-rect-color-selected: var(--a-tranche-color);
    --light-rect-color: rgba(176, 167, 255, 0.2);
    --light-rect-color-selected: var(--a-tranche-color);
    --dark-toggle-fill: #26254A;
    --light-toggle-fill: #F9F9FE;
    --dark-toggle-stroke: rgba(255, 255, 255, 0.1);
    --light-toggle-stroke: #E9E9FC;
    --dark-toggle: #3B3765;
    --light-toggle: white;
    --dark-hover-line-stroke: white;
    --light-hover-line-stroke: rgb(0, 0, 0, .5);
    --a-tranche-color: #7277FF;
    --b-tranche-color: #9B6BFF;    
}

.backdrop-line {
    fill:none;
    stroke: #26254A;
    stroke: var(--dark-toggle-fill);
    stroke-width: 7px;
}

.light .backdrop-line {
    stroke: white;
    stroke: var(--light-background)
}

.hover-circle { 
    stroke: white;
    stroke-width: 4px;
    fill: #7277FF;
    fill: var(--a-tranche-color)
}

.hover-circle.trancheB {
    fill: #9B6BFF;
    fill: var(--b-tranche-color)
}

.legend-rect {
    width: 15px;
    height: 15px;
}

.legend-rect.trancheB {
    fill: #9B6BFF;
    fill: var(--b-tranche-color);    
}

.legend-rect.trancheA {
    fill: #7277FF;
    fill: var(--a-tranche-color);    
}

.toggle-button {
    fill: #3B3765;
    fill: var(--dark-toggle);
}

.toggle-rect {
    fill: #26254A;
    fill: var(--dark-toggle-fill);
    stroke: rgba(255, 255, 255, 0.1);
    stroke: var(--dark-toggle-stroke);
    stroke-width: 0.8px;
    /* #26254A dark mode toggle color */
}

.bar {
    fill: rgba(176, 167, 255, 0.2);
    fill: var(--dark-rect-color)
}

.selected {
    fill: #7277FF;
    fill: var(--dark-rect-color-selected)
}

.selected.trancheB {
    fill: #9B6BFF;
    fill: var(--b-tranche-color)
}

div, input, button {
    font-family: 'Inter', sans-serif;
}

.chart-wrapper {
    border-radius: 10px;
    background: rgb(233, 233, 252, .05);
    background: var(--dark-background);
}

.hover-line {
    stroke-dasharray: 4, 4;
    stroke: white;
    stroke: var(--dark-hover-line-stroke);
    stroke-width: 2;
}

.tranche-text {
    fill: white;
    fill: var(--dark-text-color);
    text-anchor: middle;
}

.text-12 {
    font-size: 12px;
}
.light .chart2-mainlabel {
    fill: rgba(57, 63, 86, 1);
    fill: var(--light-title-color)
}

.text-24 {
    font-size: 24px;
}

.text-10 {
    font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 12px;
}

.text-bold {
    font-weight: 700;
}

.text-end {
    text-anchor: end;
}

.tranche-line {    
    fill: none;
    stroke-width: 4;    
}

.tranche-line.trancheA {
    stroke: #7277FF;
    stroke: var(--a-tranche-color)
}

.tranche-line.trancheB {
    stroke: #9B6BFF;
    stroke: var(--b-tranche-color)
}

hr {
    stroke-width: 2px;
    stroke: #E9E9FC;
    opacity: .1;
    margin-top: 32px
}

.chart2-mainlabel {
    fill: white;
    opacity: 0.5;
    text-anchor: middle;
    font-weight: 600;
    font-size: 14px;
}

.chart-title {
    color: white;
    color: var(--dark-text-color);
    font-size: 18px;
    font-weight: 700;
    margin-top: 32px
}
.chartFirst{
  margin-left: 45px;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 600px){
  .chartFirst{
    margin-top: 0 !important;
    margin-bottom: -20px;
    margin-left: 0;
    text-align: center;
    flex-direction: column;
    top: 10px;
    font-size: 12px;
  }
}
.main-title {
    fill: white;
    font-size: 18px;
    font-weight: 700;
    text-anchor: middle;
}

/* light mode overrides */

.light .chart-title {
    color: #393F56;
    color: var(--light-text-color);
}

.light .tranche-text {
    fill: #393F56;
    fill: var(--light-text-color);
}

.light .chart-wrapper {
    background: white;
    background: var(--light-background)
}

.light .bar {
    fill: rgba(176, 167, 255, 0.2);
    fill: var(--light-rect-color)
}

.light .selected {
    fill: #7277FF;
    fill: var(--light-rect-color-selected)
}

.light .selected.trancheB {
    fill: #9B6BFF;
    fill: var(--b-tranche-color)
}

.light .toggle-button {
    fill: white;
    fill: var(--light-toggle);
}

.light .toggle-text {
    fill: rgba(68, 65, 207, 1);
}

.light .hover-line {
    stroke: rgb(0, 0, 0, .5);
    stroke: var(--light-hover-line-stroke)
}

.light .toggle-rect {
    fill: #F9F9FE;
    fill: var(--light-toggle-fill);
    stroke: #E9E9FC;
    stroke: var(--light-toggle-stroke);     
}










:root {
    --dark-solvency-line-stroke: white;
    --light-solvency-line-stroke: rgba(57, 63, 86, 1);
    --light-title-color: rgba(57, 63, 86, 1);
    --dark-title-color: white;
    --light-abscissa-stroke: #DEDEFF;
    --dark-abscissa-stroke: white;
    --dark-frame-stroke: white;
    --light-frame-stroke: #F0F0F6;
    --dark-selected-ordinate-stroke: white;
    --light-selected-ordinate-stroke: #DEDEFF;

    --dark-solvency-circle-fill: #1B1A40;
    --light-solvency-circle-fill: #393F56;
    
    --light-solvency-stroke: white;
    --dark-solvency-stroke: white;


    --upperLineColor: #9B6BFF;
    --lowerLineColor: #7277FF;
}

.tranche-circle {
    stroke-width: 2px;
    stroke: white;
    stroke: var(--dark-solvency-stroke);
    /* cursor: grab;     */
}

.tranche-circle.upper {
    fill: #9B6BFF;
    fill: var(--upperLineColor)
}

.tranche-circle.lower {
    fill: #7277FF;
    fill: var(--lowerLineColor)
}

.solvency-circle {
    stroke-width: 2px;
    stroke: white;
    stroke: var(--dark-solvency-stroke);
    fill: #1B1A40;
    fill: var(--dark-solvency-circle-fill);
    cursor: -webkit-grab;
    cursor: grab;
}
.selected-ordinate {
    stroke-dasharray: 6, 6;
    opacity: .3;
    stroke-width: 2px;
    stroke: white;
    stroke: var(--dark-selected-ordinate-stroke);
}

.frame {
    opacity: .3;
    stroke-width: 2px;
    stroke: white;
    stroke: var(--dark-frame-stroke)
}

.abscissa {
    stroke-dasharray: 6, 6;
    opacity: .3;
    stroke-width: 2px;
    stroke: white;
    stroke: var(--dark-abscissa-stroke)
}

.solvency-line {
    stroke-width: 4px;
    stroke: white;
    stroke: var(--dark-solvency-line-stroke)
}

.solvency-current {
    stroke-width: 2px;
}

div, input, button {
    font-family: 'Inter', sans-serif;
}

.chart-wrapper {
    border-radius: 10px;
    background: rgb(233, 233, 252, .05);
}

hr {
    stroke-width: 2px;
    stroke: #E9E9FC;
    opacity: .1;
    margin-top: 32px
}

.chart2-mainlabel{
    fill: white;
    fill: var(--dark-title-color);
    opacity: 0.5;
    /* text-anchor: middle; */
    font-weight: 600;
    font-size: 14px;
}

.chart-title {
    color: white;
    font-size: 18px;
    font-weight: 700;
    margin-top: 32px
}

.main-title {
    fill: white;
    font-size: 18px;
    font-weight: 700;
    text-anchor: middle;
}
.text-start {
  text-anchor: start;
}
.text-middle {
  text-anchor: middle;
}
.helper-text {
    opacity: .3;
    font-size: 14px;
    color: white;
}

.tranche-line {
    fill: none;
    stroke-width: 3;
}

.tranche-line.upper {
    stroke: #9B6BFF;
    stroke: var(--upperLineColor)
}

.tranche-line.lower {
    stroke: #7277FF;
    stroke: var(--lowerLineColor)
}

.light .helper-text {
    color: black
}

.light .solvency-line {
    stroke: rgba(57, 63, 86, 1);
    stroke: var(--light-solvency-line-stroke)
}

.light .chart2-mainlabel {
    fill: rgba(57, 63, 86, 1);
    fill: var(--light-title-color)
}

.light .abscissa {
    stroke: #DEDEFF;
    stroke: var(--light-abscissa-stroke);
    opacity: 1;
}

.light .frame {
    opacity: 1;
    stroke: #F0F0F6;
    stroke: var(--light-frame-stroke)
}

.light .selected-ordinate {
    stroke: #DEDEFF;
    stroke: var(--light-selected-ordinate-stroke);
    opacity: 1;
}

.light .solvency-circle {
    stroke: white;
    stroke: var(--light-solvency-stroke);
    fill: #393F56;
    fill: var(--light-solvency-circle-fill);
}

.light .tranche-circle {    
    stroke: white;    
    stroke: var(--light-solvency-stroke);
}




.loadingFirstCol{
    width: 28%;
  }
  .loadingAvatar{
    height: 47px;
    width: 47px;
    border-radius: 50%;
  }
  
  .loadingCard{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .loadingFirslColContent{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .loadingText{
    margin-left: 18px;
    width: 80%;
    height: 47px;
  }
  
  .loadingContentCol{
    width: 100%;;
    height: 47px;
  }
  .loadingFifthColContent{
    width: 100%;;
    height: 47px;
  }
  
  .loadingSixthColContent{
    width: 100%;;
    height: 47px;
  }
  .loadingSecondCol{
    width: 45%;
  }
  
  .loadingFifthCol{
    width: 12%;
  }
  .loadingSixthCol{
    width: 12%;
  }
   
@-webkit-keyframes gradient-slide {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0% 50%;
    }
  }
   
@keyframes gradient-slide {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0% 50%;
    }
  }

  .react-multi-carousel-track{
    margin: 0 0 42px 0;
  }



  .slick-list{
    overflow: visible !important;
  }



  .example-track {
    position: relative;
    background: rgb(221, 221, 221);
}





.container{ 
  padding-right: 15px; 
  padding-left: 15px; 
  margin-right: auto; 
  margin-left: auto; 
}

@media(min-width: 768px){ 
  .container{ 
    width: 750px; 
  }
} 

@media(min-width: 992px){ 
  .container{ 
    width: 970px; 
  }
} 

@media (min-width: 1200px){ 
  .container{ 
    width: 1200px;
  }
}

.ph-wrapper{
  margin: 0;
}

@-webkit-keyframes HeaderMapDots {
  0%   { opacity:  0.7; -webkit-filter: brightness(1); filter: brightness(1); fill: #101010; }
  25%   { opacity: 0.8; -webkit-filter: brightness(1.2); filter: brightness(1.2); fill: #4441CF; }
  50%  { opacity: 0.8; -webkit-filter: brightness(1.2); filter: brightness(1.2); fill: #4441CF; }
  100%  { opacity: 0.7; -webkit-filter: brightness(1); filter: brightness(1); fill: #101010; }
}

@keyframes HeaderMapDots {
  0%   { opacity:  0.7; -webkit-filter: brightness(1); filter: brightness(1); fill: #101010; }
  25%   { opacity: 0.8; -webkit-filter: brightness(1.2); filter: brightness(1.2); fill: #4441CF; }
  50%  { opacity: 0.8; -webkit-filter: brightness(1.2); filter: brightness(1.2); fill: #4441CF; }
  100%  { opacity: 0.7; -webkit-filter: brightness(1); filter: brightness(1); fill: #101010; }
}

@-webkit-keyframes IconImgAnimation {
  0%   { transform: translateY(0); }
  25%  { transform: translateY(-15px); }
  50%  { transform: translateY(-10px); }
  75%  { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

@keyframes IconImgAnimation {
  0%   { transform: translateY(0); }
  25%  { transform: translateY(-15px); }
  50%  { transform: translateY(-10px); }
  75%  { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

@-webkit-keyframes firstIconAnimation {
  from { transform: translate(-30px, -30px); opacity: 0.2; }
  to { transform: translate(0, 0); opacity: 1}
}

@keyframes firstIconAnimation {
  from { transform: translate(-30px, -30px); opacity: 0.2; }
  to { transform: translate(0, 0); opacity: 1}
}

@-webkit-keyframes secondIconAnimation {
  from { transform: translate(-30px, 0); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}

@keyframes secondIconAnimation {
  from { transform: translate(-30px, 0); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}

@-webkit-keyframes thirdIconAnimation {
  from { transform: translate(-30px, 30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}

@keyframes thirdIconAnimation {
  from { transform: translate(-30px, 30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@-webkit-keyframes fourthIconAnimation {
  from { transform: translate(30px, -30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@keyframes fourthIconAnimation {
  from { transform: translate(30px, -30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@-webkit-keyframes fifthIconAnimation {
  from { transform: translate(30px, 0); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@keyframes fifthIconAnimation {
  from { transform: translate(30px, 0); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@-webkit-keyframes sixthIconAnimation {
  from { transform: translate(30px, 30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@keyframes sixthIconAnimation {
  from { transform: translate(30px, 30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@-webkit-keyframes seventhHeaderImg {
  from { transform: translate(30px, -30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@keyframes seventhHeaderImg {
  from { transform: translate(30px, -30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@-webkit-keyframes eighthHeaderImg {
  from { transform: translate(-30px, 30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}
@keyframes eighthHeaderImg {
  from { transform: translate(-30px, 30px); opacity: 0.2;}
  to { transform: translate(0, 0); opacity: 1}
}

#firstHeaderImg{
  -webkit-animation: firstIconAnimation 1s ease-out 1;
          animation: firstIconAnimation 1s ease-out 1;
}
#secondHeaderImg{
  -webkit-animation: secondIconAnimation 1s ease-out 1;
          animation: secondIconAnimation 1s ease-out 1;
}
#thirdHeaderImg{
  -webkit-animation: thirdIconAnimation 1s ease-out 1;
          animation: thirdIconAnimation 1s ease-out 1;
}
#fourthHeaderImg{
  -webkit-animation: fourthIconAnimation 1s ease-out 1;
          animation: fourthIconAnimation 1s ease-out 1;
}
#fifthHeaderImg{
  -webkit-animation: fifthIconAnimation 1s ease-out 1;
          animation: fifthIconAnimation 1s ease-out 1; 
}
#sixthHeaderImg{
  -webkit-animation: sixthIconAnimation 1s ease-out 1;
          animation: sixthIconAnimation 1s ease-out 1;
}
#seventhHeaderImg{
  -webkit-animation: seventhHeaderImg 1s ease-out 1;
          animation: seventhHeaderImg 1s ease-out 1;
}
#eighthHeaderImg{
  -webkit-animation: eighthHeaderImg 1s ease-out 1;
          animation: eighthHeaderImg 1s ease-out 1;
}



#HeaderMap{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  z-index: -1;
}
@media (max-width: 990px){
  #HeaderMap{
    display: none;
  }
}
.fadeAnimation{
  -webkit-animation: HeaderMapDots 4s ease-in-out infinite;
          animation: HeaderMapDots 4s ease-in-out infinite;
}
#fadeAnimation-first{
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
#fadeAnimation-second{
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
#fadeAnimation-third{
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
#fadeAnimation-fourth{
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
#fadeAnimation-fifth{
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
#fadeAnimation-sixth{
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
#fadeAnimation-seventh{
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
#fadeAnimation-eighth{
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
#fadeAnimation-ninth{
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
#fadeAnimation-tenth{
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
